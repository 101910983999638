import type {
  GetContextMenuItemsParams,
  GetQuickFilterTextParams,
  GridApi,
  GridOptions,
  ICellRendererParams,
  IRowNode,
  MenuItemDef,
  ProcessCellForExportParams,
} from 'ag-grid-enterprise';
import { compact, first, isArray, isObject, last } from 'lodash';
import { logger } from '../../utils';
import { getCellDisplayValue } from '../AgGrid/agGridGetCellValue';
import type { BlotterTableContextProps } from './BlotterTableContext';
import { AGGRID_AUTOCOLUMN_ID } from './types';

/** This list of imports must be updated as we support more locales.
 *  We have a test in BlotterTable.test.tsx to ensure that we don't miss adding new supported locale imports here. */
import {
  AG_GRID_LOCALE_DE,
  AG_GRID_LOCALE_EN,
  AG_GRID_LOCALE_ES,
  AG_GRID_LOCALE_FR,
  AG_GRID_LOCALE_IT,
  AG_GRID_LOCALE_PL,
} from '@ag-grid-community/locale';

/**
 * Given a list of nodes, which may be either group or leaf nodes, return all
 * the leaf nodes.
 *
 * @param node The nodes to operate on
 * @returns All leaf nodes under the given nodes
 */
export function getAllLeafNodesAfterFilter(node: IRowNode | IRowNode[]): IRowNode[] {
  if (isArray(node)) {
    return node.flatMap(childNode => getAllLeafNodesAfterFilter(childNode));
  }
  if (!node.group) {
    return [node];
  }
  if (!node.childrenAfterFilter) {
    return [];
  }

  return node.childrenAfterFilter.flatMap(childNode => getAllLeafNodesAfterFilter(childNode));
}

/**
 * Gets all nodes found within the provided group node by traversing the tree within recursively.
 * If the provided groupNode is not a group (groupNode.group === false), returns empty array.
 *
 * Does **not** include the groupNode itself in the returned array.
 */
export function getAllNodesInGroup(groupNode: IRowNode): IRowNode[] {
  if (!groupNode.group) {
    return [];
  }

  const nodes: IRowNode[] = [];
  performActionForChildNodesRecursively(groupNode, node => nodes.push(node));
  return nodes;
}

export function expandAllNodesInGroup(groupNode: IRowNode): void {
  if (!groupNode.group) {
    return;
  }

  performActionForChildNodesRecursively(groupNode, node => node.setExpanded(true));
}

/**
 * Perform some action on behalf of the passed node and all its possible child nodes as well recursively
 */
function performActionForChildNodesRecursively(node: IRowNode, action: (node: IRowNode) => void) {
  action(node);
  node.childrenAfterFilter?.forEach(node => performActionForChildNodesRecursively(node, action));
}

/**
 * Get the CSV export data for a set of row nodes.
 *
 * @param params Params object from an ag-grid callback
 * @param nodeIds A set of row node ids that should be exported
 * @returns CSV export of the selected nodes, for the currently visible columns
 */
export function agGridGetCSV(params: GetContextMenuItemsParams | ICellRendererParams, nodeIds: Set<string>): string {
  return (
    params.api.getDataAsCsv({
      skipRowGroups: true,
      onlySelected: false,
      suppressQuotes: false,
      columnSeparator: ',',
      // Only include columns that are visible with header names to exclude action button / spacer columns
      columnKeys: params.api.getAllDisplayedColumns()?.filter(col => col.getColDef().headerName !== ''),
      processCellCallback: getParamsFormatted,
      shouldRowBeSkipped(innerParams) {
        if (!innerParams.node?.id) {
          return true;
        }
        return !nodeIds.has(innerParams.node.id);
      },
    }) ?? ''
  );
}

export type ExportGridMode = 'CSV' | 'Excel';

/** Expanded AgGrid Context ({@link BlotterTableContextProps}) type that supports
 * value retrieval for custom grouping levels */
export interface GetValueForGroupNodeContext extends BlotterTableContextProps {
  /** Custom Override for Retrieving Group Node data */
  getValueForGroupedNode: (node: IRowNode, mode: ExportGridMode) => string;
}

function isGetValueForGroupNodeContext(context: BlotterTableContextProps): context is GetValueForGroupNodeContext {
  return (context as GetValueForGroupNodeContext)?.getValueForGroupedNode != null;
}

/**
 * Get a formatted value for an ag-grid cell
 * @param params Params object from an ag-grid callback
 * @returns String representation of the cell value
 */
export function getParamsFormatted(
  params: GetContextMenuItemsParams | ProcessCellForExportParams,
  mode: ExportGridMode = 'CSV'
): string {
  // If we're working with a cell in the grouped column, we want to iterate over all layers and print the grouping structure
  // Printing a result like: "BTC > Future" for example
  const node = params.node;
  const column = params.column;
  if (column && column.getColId() === AGGRID_AUTOCOLUMN_ID && node) {
    // For tree-value grids, use the callback to process how to get the value for the group node
    if (isGetValueForGroupNodeContext(params.context.current)) {
      return params.context.current.getValueForGroupedNode(node, mode);
    }

    const isTreeBlotter = params.api.getGridOption('treeData');
    if (isTreeBlotter) {
      const parents = getAllParentsOfNodeInclusive(node);
      return parents
        .reverse()
        .map(parentNode => {
          // If the Tree node has data on it, then there'll be a valueGetter implemented to resolve the value from data. If not, use the node.key.
          // The node.key in a tree row will be the node's element of the dataPath. This'll usually be an ID.
          // If there is no node.data, then we assume that the implementer's valueFormatter will still properly convert node.key to a display label.
          const value = parentNode.data
            ? params.api.getCellValue({ colKey: column, rowNode: parentNode })
            : parentNode.key;

          return (
            getCellDisplayValue({
              ...params,
              node: parentNode,
              value: value,
            }) ?? 'None'
          );
        })
        .join(' > ');
    }

    // Else regular row-grouped blotter
    const rowGroupColumns = params.api.getRowGroupColumns();
    return (
      rowGroupColumns
        // We need to get the correct value at each layer of grouping, and also pass the column for the layer of grouping
        .map(
          column =>
            getCellDisplayValue({
              ...params,
              value: params.api.getCellValue({ colKey: column, rowNode: node }),
              column: column,
            }) ?? 'None'
        )
        .join(' > ')
    );
  }

  const formattedValue = getCellDisplayValue(params);

  // If we got a string, return that
  if (typeof formattedValue === 'string') {
    return formattedValue;
  }

  // For size/price the value is an object containing value and currency.
  // {value: '100', currency: 'USD'}
  // In those cases access the numeric value.
  if (typeof formattedValue?.value === 'string') {
    return formattedValue.value;
  }

  // In some cases our valueGetters return arrays or objects which are then exported as [object object]. This code forces
  // them to be formatted to strings.
  if (isObject(params.value) || isArray(params.value)) {
    try {
      return JSON.stringify(params.value);
    } catch (e) {
      logger.error(e as Error);
    }
  }

  if (params.value == null) {
    return '';
  }

  return params.value.toString();
}

/**
 * Ensure that the csv cell value returned by aggrid is prefixed if the content is a formula
 */
export function cellCsvSafety(cellContent: string): string {
  // https://owasp.org/www-community/attacks/CSV_Injection
  let needsSafetyPrefix = ['=', '@', '+'].some(char => cellContent.startsWith(char));
  if (cellContent.startsWith('-')) {
    // if -, test if the cell content is a number
    if (cellContent.length > 1 && !Number.isNaN(Number(cellContent.slice(1)))) {
      return cellContent;
    }
    needsSafetyPrefix = true;
  }
  return needsSafetyPrefix ? `'${cellContent}` : cellContent;
}

export const alphabeticalGroupOrder =
  /**
   * Our default group order comparator, to ensure groups are sorted alphabetically by default
   * @param params Ag grid group order params
   */
  function alphabeticalGroupOrder({ nodeA, nodeB, api }) {
    // The params passed to this group ordering function don't work out of the box with the getCellDisplayValue expected params
    // so we hook them up correctly here and attempt to do the ordering comparison on the display values
    const nodeADisplayValue =
      getCellDisplayValue({
        node: nodeA,
        column: nodeA.rowGroupColumn,
        value: nodeA.key,
        api,
      }) || '';

    const nodeBDisplayValue =
      getCellDisplayValue({
        node: nodeB,
        column: nodeB.rowGroupColumn,
        value: nodeB.key,
        api,
      }) || '';

    return nodeADisplayValue.localeCompare?.(nodeBDisplayValue);
  } satisfies GridOptions['initialGroupOrderComparator'];

/**
 * Removes repeated and last "separator" entries in a list of string | MenuItemDef
 */
export function removeUnnecessarySeparators(items: (string | MenuItemDef)[]) {
  const result = items.reduce((arr, item, index) => {
    if (item === 'separator' && arr[arr.length - 1] === item) {
      return arr;
    }

    arr.push(item);
    return arr;
  }, [] as (string | MenuItemDef)[]);

  if (last(result) === 'separator') {
    result.splice(result.length - 1, 1);
  }

  if (first(result) === 'separator') {
    result.splice(0, 1);
  }

  return result;
}

/** Defines the AgGrid "basic params properties" we need to make this function work correctly. */
type IsEditingThisCellParams = Pick<ICellRendererParams, 'api' | 'column' | 'node'>;

/**
 * Given some set of basic AgGrid function params, returns whether or not the current node is being edited.
 */
export function isEditingThisCell(params: IsEditingThisCellParams): boolean {
  const cellsBeingEdited = params.api.getEditingCells();

  // Go through all cells that are being edited right now and see if any of these cells are us.
  return cellsBeingEdited != null
    ? cellsBeingEdited.some(
        cell => cell.column.getColId() === params.column?.getColId() && cell.rowIndex === params.node.rowIndex
      )
    : false;
}

/**
 * Given a node, will traverse through the node.parent property upwards until it hits the root element of the blotter
 * The returned array of nodes is inclusive, meaning that the given starting node itself will also be included
 * @param node The starting node
 * @returns a list of nodes, where the first node is the starting node, and then each later index is the next parent upwards.
 * If you want the list to start with the top-level parent of this node, simply do Array.reverse() on the returned list.
 */
export function getAllParentsOfNodeInclusive(node: IRowNode<any>): IRowNode<any>[] {
  const parents: IRowNode<any>[] = [];
  let workingNode = node;

  // In AgGrid, the omnipresent ROOT_NODE has level=-1. Iterate while level is 0 or above.
  while (workingNode.level >= 0) {
    parents.push(workingNode);
    if (workingNode.parent == null) {
      break;
    }
    workingNode = workingNode.parent;
  }

  return parents;
}

/**
 * This function returns whether or not the provided node is expanded, as in is taller / greater in height, than the
 * default row height for the grid.
 */
export function isRowHeightExpanded(node: IRowNode<unknown>, api: GridApi<unknown>): boolean {
  const defaultRowHeight = api.getGridOption('rowHeight');
  if (defaultRowHeight == null || node.rowHeight == null) {
    return false;
  }

  return node.rowHeight > defaultRowHeight;
}

/** Default AgGrid params to do multiselection of rows without checkboxes being present */
export const DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS: GridOptions['selection'] = {
  mode: 'multiRow',
  checkboxes: false,
  headerCheckbox: false,
  enableClickSelection: true,
};

export const DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS: GridOptions['selection'] = {
  mode: 'singleRow',
  checkboxes: false,
  enableClickSelection: true,
};

/** Type Guard that validates that AgGrid api is not destroyed */
export function isGridApiReady<T>(api: GridApi<T> | null | undefined): api is GridApi<T> {
  return api != null && api.isDestroyed() === false;
}

/**
 * This function returns the AgGrid API if it is not destroyed, otherwise it returns undefined.
 *
 * @example
 * ```ts
 * safeGridApi(gridApi)?.removeEventListener('modelUpdated', handleModelUpdated);
 * ```
 *
 * @param api The AgGrid API to check. Allows you to pass undefined / null for simplicity
 * @returns The AgGrid API if it is not destroyed, otherwise undefined
 */
export function safeGridApi<TData = any>(api: GridApi<TData> | null | undefined): GridApi<TData> | undefined {
  if (!isGridApiReady(api)) {
    return undefined;
  }
  return api;
}

const FILTER_PARSER_REGEX = /(?:"([^"\n]*?)")|(?:([^\s]+))/;
export function quickFilterParser(text: string | unknown): string[] {
  // not sure on exact type in different cases, so being defensive here
  if (typeof text !== 'string') {
    return [];
  }

  /**
   * this regex is complicated but what this regex + mapping does is this:
   *
   * 'test test' -> ['test', 'test']
   * '"test test"' -> ['test test']
   * '"phrase one" "phrase two"' -> ['phrase one', 'phrase two']
   * '"phrase one" word word word' -> ['phrase one', 'word', 'word', 'word']
   */
  return compact(text.split(FILTER_PARSER_REGEX).map((part: string | undefined) => part?.trim()));
}

/**
 * Our own implementation of the default getQuickFilterText. This function is used to go from a node + column (as in: a cell) to some searchable string.
 *
 * See AgGrid's default implementation
 * https://github.com/ag-grid/ag-grid/blob/26149bf63cc8909b163d84fc64df5626f527a2a8/packages/ag-grid-community/src/filter/quickFilterService.ts#L197
 *
 * It uses the column's filterValueGetter, which we don't want to do. We want to be searching on the formatted value, as
 * in, what's visible to the user in the blotter. We also add in the valueGetter result as well (as long as it is of type string)
 *
 */
export function defaultGetQuickFilterText({ value, ...params }: GetQuickFilterTextParams): string {
  // calling api.getCellValue can be expensive so we do some quick thinking here to figure out if we need to call it or not based on some assumptions

  // Firstly, getQuickFilterText is invoked with the filterValue of the column. But if the column doesn't have any filterValueGetter, it'll be the valueGetter's result.
  const valueIsValueGetterReturn = params.column.getColDef().filterValueGetter == null;
  const valueGetterValue = valueIsValueGetterReturn
    ? value
    : params.api.getCellValue({ rowNode: params.node, colKey: params.column });

  // We also want to grab the formatted value, but we only do this if we see that the column has a valueFormatter defined. If not, we already have the value this'd return directly above
  const valueFormatterValue = params.column.getColDef().valueFormatter
    ? params.api.getCellValue({ rowNode: params.node, colKey: params.column, useFormatter: true })
    : undefined;

  // We're searching on _both_ the value and formatted value. This is to give easy support for cases where the user copy-pastes a complete ID
  return `${typeof valueGetterValue === 'string' ? valueGetterValue : ''} ${valueFormatterValue ?? ''}`;
}

/**
 * Given a node, returns the gridApi. Useful for cases where we need to go beyond the standard capabilities in our columns, such as comparators for example,
 * where we want to check things using the api, but have no other way to achieve it.
 *
 * This should only be used as a last resort and after questioning yourself at least 3 times. :)
 */
interface ExtendedRowNode extends IRowNode {
  beans?: { gridApi?: GridApi };
}
export function getGridApiFromNode(node: ExtendedRowNode | undefined): GridApi | undefined {
  try {
    return node?.beans?.gridApi;
  } catch (e) {
    return undefined;
  }
}

/**
 * Returns the Ag Grid locale text for the given locale.
 *
 * @param locale The locale string (e.g., 'de', 'it', or 'fr-FR').
 * @returns The locale text object corresponding to the given locale.
 */
export function getAgGridLocaleText(locale: string) {
  switch (locale.split('-')[0]) {
    case 'de':
      return AG_GRID_LOCALE_DE;
    case 'it':
      return AG_GRID_LOCALE_IT;
    case 'pl':
      return AG_GRID_LOCALE_PL;
    case 'fr':
      return AG_GRID_LOCALE_FR;
    case 'es':
      return AG_GRID_LOCALE_ES;
    default:
      return AG_GRID_LOCALE_EN;
  }
}
